import $ from "jquery";
window.jQuery = $;
window.jquery = $;

require("./vendor/jquery.sieve.min");
require("bootstrap");
require("bootstrap-select");

require("daterangepicker");
require("../node_modules/daterangepicker/daterangepicker.css");

require("./style.scss");
require("../node_modules/@fortawesome/fontawesome-free/js/all.min.js");
const Cookies = require("js-cookie");

const loadOptions = function (button, dropDownId, URL, allowNone = false) {
  const dropdown = $("#" + dropDownId);
  let selectedItems = $.map(dropdown.find(":selected"), function (e) {
    return e.value;
  });

  $.ajaxSetup({
    data: {
      csrf_itdsToken: Cookies.get("csrf_itdsCookie"),
    },
  });

  $.ajax({
    type: "GET",
    url: URL,
    beforeSend: function () {
      button.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`);
    },
    success: function (data) {
      data = JSON.parse(data);

      dropdown.empty();

      if (allowNone) {
        dropdown.append($("<option></option>").val("").html("-----------------"));
      }

      for (const item of data) {
        let value = item;
        let display = item;

        if (typeof item === "object" && "ID" in item && "Name" in item) {
          value = item["ID"];
          display = item["Name"];
        }

        value = escapeHtml(value);
        display = escapeHtml(display);

        if (selectedItems.includes(value)) {
          dropdown.append($("<option selected=selected></option>").val(value).html(display));
        } else {
          dropdown.append($("<option></option>").val(value).html(display));
        }
      }

      dropdown.selectpicker("refresh");
      button.html("Refresh");
    },
    error: function () {
      $("#ajax-panel").html('<p class="error"><strong>Oops!</strong> Try that again in a few moments.</p>');
    },
  });
};

$("#load-output-entities").on("click", function () {
  loadOptions($(this), "transform_output_entities", "/entity/listJSON/");
});

$("#load-entities").on("click", function () {
  loadOptions($(this), "trans_IE", "/entity/listJSON/");
});

$("#load-transform-settings").on("click", function () {
  loadOptions($(this), "ts_Settings", "/transformsettings/listJSON/");
});

$("#load-seeds").on("click", function () {
  loadOptions($(this), "trans_Seeds", "/seeds/listJSON");
});

$("#load-config-seeds").on("click", function () {
  loadOptions($(this), "seedIDs", "/seeds/listJSON");
});

$("#load-paired-configs").on("click", function () {
  loadOptions($(this), "seeds_config", "/configurations/listJSON", true);
});

$("#load-transforms").on("click", function () {
  loadOptions($(this), "seeds_transforms", "/transforms/listJSON");
});

$("#load-oauth").on("click", function () {
  loadOptions($(this), "trans_OAuthSetting", "/OAuthSettings/listJSON", true);
});

$(".checkbox-single").on("change", function () {
  const selectedItems = $.map($(".checkbox-single:checked"), function (e) {
    return e.value;
  });
  $("[name='selected-items']").val(selectedItems.join());
  setDeleteBtnVisibility();
});

$("#checkbox-all").on("change", function () {
  if ($("#checkbox-all").is(":checked")) {
    $(".checkbox-single:visible").prop("checked", true);
    const selectedItems = $.map($(".checkbox-single:checked"), function (e) {
      return e.value;
    });
    $("[name='selected-items']").val(selectedItems.join());
    setDeleteBtnVisibility();
  } else {
    $(".checkbox-single").prop("checked", false);
    $("[name='selected-items']").val("");
    setDeleteBtnVisibility();
  }
});

function setDeleteBtnVisibility() {
  if ($("[name='selected-items']").val()) {
    $("#delete-selected").show();
  } else {
    $("#delete-selected").hide();
  }
}

$("#delete-selected").on("click", function () {
  const selectedItems = $.map($(".checkbox-single:checked"), function (e) {
    return e.value;
  });
  if (selectedItems.length > 999) {
    alert("Maximum 999 rows are allowed to delete per action");
    return;
  } else {
    const agree = confirm(`Do you want to delete ${selectedItems.length} item(s)?`);
    if (!agree) {
      return;
    }
  }

  $.ajaxSetup({
    data: {
      csrf_itdsToken: Cookies.get("csrf_itdsCookie"),
    },
  });

  const currentPath = $("#current-path").val();

  $.ajax({
    type: "POST",
    url: `/${currentPath}/deleteSelectedItems`,
    data: { selectedItems },
    success: (response) => {
      if (response === "success") {
        $(".checkbox-single:checked").each(function () {
          $(this).closest("tr").remove();
        });
        $("#checkbox-all").prop("checked", false);
        $("[name='selected-items']").val("");
        setDeleteBtnVisibility();
      } else {
        alert("Oops! Something went wrong when deleting the item(s)");
      }
    },
    error: function () {
      alert("Oops! Something went wrong when deleting the item(s)");
    },
    dataType: "json",
  });
});

function updateSettingType(){
  let typeInput = $(".transform-settings-type");
  let defaultValueInput = $(".transform-settings-default-value");
  const settingType = typeInput.val();
  
  let dp = defaultValueInput.data("daterangepicker")

  if (dp !== undefined) {
    dp.remove()
  }
  
  const currentValue = defaultValueInput.val()

  function createBoolSelect() {
    var values = {
      '': '--------------',
      'True': 'True',
      'False': 'False',
    }
    var selectElement = document.createElement("select");
    selectElement.name = "DefaultValue";
    selectElement.className = "form-control transform-settings-default-value"

    for (const val in values) {
      var option = document.createElement("option");
      option.value = val;
      option.text = values[val];
      
      if (val === currentValue) {
        option.selected = true
      }
      
      selectElement.appendChild(option);
    }
    return selectElement
  }

  function createInput(type) {
    var inputElement = document.createElement("input");
    inputElement.name = "DefaultValue";
    inputElement.className = "form-control transform-settings-default-value"
    inputElement.type = type
    inputElement.maxlength="500"
    inputElement.value=currentValue
    return inputElement
  }


  if (settingType === "int") {
    let newElement = createInput('number')
    defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "double") {
    let newElement = createInput('number')
    newElement.step = "any"
    console.log('stepping')
    defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "boolean") {
    let newElement = createBoolSelect()
    defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "string") {
    let newElement = createInput('text')
    defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "date") {
   let newElement = createInput('date')
   defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "datetime") {
    let newElement = createInput('datetime-local')
    defaultValueInput.replaceWith(newElement)
  } 
  else if (settingType === "daterange") {
    let newElement = createInput('text')
    defaultValueInput.replaceWith(newElement)

    newElement = $(newElement);

    newElement.daterangepicker({
      timePicker: true,
      autoUpdateInput: false,
      locale: { 
        cancelLabel: 'Clear',
        format: 'DD/MM/YYYY hh:mm'
       }
    });
    
    newElement.val(currentValue);

    newElement.on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
    newElement.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY hh:mm') + ' - ' + picker.endDate.format('DD/MM/YYYY hh:mm'));
  });
  }
}

$(".transform-settings-type").on("change", function () {
  $(".transform-settings-default-value").val("");
  updateSettingType()
});


function checkForUpdate(serverType) {
  $.get("https://registry.paterva.com/versions/", function (data) {
    var versions = data[serverType] || [];
    if (versions.length > 0) {
      versions.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      var latest_version = versions[0];
      showUpdate(latest_version);
    } else {
      showManualUpdate();
    }
  });
}

function showUpdate(latest_version) {
  var currentVersion = $("#server-version").text();

  if (latest_version.version.indexOf(currentVersion) === -1) {
    $("#update-version").text(latest_version.version);
    $("#update-release_date").text(new Date(latest_version.timestamp * 1000).toDateString());
    $("#update-card").show();
  }
}

const initFilterTables = () => {
  const searchTemplate = "<div class='form-group'><input class=\"form-control\" type='text' placeholder='Filter table'></div>";
  $("#filter-table").sieve({ searchTemplate: searchTemplate });
};

const handleConfirmations = () => {
  const one = $("#reset-cert-confirm-one");
  const two = $("#reset-cert-confirm-two");
  const formHolder = $("#reset-cert-form");
  const formSubmit = $("input[name='resetSubmit']");

  formSubmit.attr("disabled", "disabled");
  one.change(changedCheckbox);
  two.change(changedCheckbox);

  function changedCheckbox() {
    if (one.is(":checked") && two.is(":checked")) {
      formHolder.fadeTo("fast", 1);
      formSubmit.removeAttr("disabled");
    } else {
      formHolder.fadeTo("fast", 0.3);
      formSubmit.attr("disabled", "disabled");
    }
  }
};

$("#generate-key").on("click", function (event) {
  event.preventDefault();

  const button = $(this);
  const pubkeyTextBox = $("#publicKey");
  const privKey = $("#privKey");
  const privateKeyModal = $("#privateKeyModal");

  $.ajax({
    type: "GET",
    url: "/OAuthSettings/generateRSAKeyPair",
    beforeSend: function () {
      button.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Loading...`);
    },
    success: function (data) {
      data = $.parseJSON(data);
      pubkeyTextBox.empty();
      pubkeyTextBox.val(data["public"]);
      privKey.html(data["private"]);
      privateKeyModal.modal();

      button.html("Generate Keypair");
    },
    error: function () {
      // failed request; give feedback to user
      $("#ajax-panel").html('<p class="error"><strong>Oops!</strong> Try that again in a few moments.</p>');
    },
  });
});

const copyToClipboard = (text) => {
  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(text).select();
  document.execCommand("copy");
  $temp.remove();
};

const showMessage = (message, level = "success") => {
  $(".message-container").append(
    `<div class='alert alert-${level} alert-dismissible fade show' role='alert'>${message}<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>X</span></button></div>`
  );
};

$(".copy-to-clip").on("click", function (e) {
  const copyText = $(this).data("clipboard-text");
  copyToClipboard(copyText);
  showMessage("Copied to clipboard.");
});

function escapeHtml(text) {
  return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
}

$(function () {
  updateSettingType()
  handleConfirmations();
  initFilterTables();

  if ($("#update-card").length) {
    checkForUpdate("ITDS");
  }

  $("#seed_url").on("keyup", function () {
    $("#URLSeed").html(escapeHtml($("#seed_url").val()));
  });

  $('[data-toggle="tooltip"]').tooltip();

  setTimeout(function() {
      $(".alert").alert('close');
  }, 4000);

});
